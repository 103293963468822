import { useEffect } from 'react';

import AccountSettingsTabs from './components/AccountSettingsTabs';
import Translations from '../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../components/common';
import TngBackNavigation from '../../components/common/TngBackNavigation';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const AccountSettings = () => {
  useEffect(() => {
    const mainDiv = document.getElementById('header_container');
    focusFirstInteractiveElement(mainDiv);
  });
  return (
    <TngGrid container className="account_settings_parent_container" id="header_container">
      <TngGrid container className="account_settings_children_container">
        <TngGrid item xs={12}>
          <TngBackNavigation
            label={Translations.common.backToDashboard}
            path={Translations.navigation.dashboard}
          />
        </TngGrid>
        <TngGrid item xs={12} className="account_settings_heading">
          <TngTypography variant="h3">
            {Translations.accountSettings.accountSettingsHeading}
          </TngTypography>
        </TngGrid>
        <TngGrid item className="account_settings_tabs">
          <AccountSettingsTabs />
        </TngGrid>
      </TngGrid>
    </TngGrid>
  );
};

export default AccountSettings;
