import React from 'react';

const SalesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.75 0.75H2.25C1.42157 0.75 0.75 1.42157 0.75 2.25V21.75C0.75 22.5784 1.42157 23.25 2.25 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V2.25C23.25 1.42157 22.5784 0.75 21.75 0.75Z"
        stroke="#00A0FB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 14.25L8.25 9.75L14.25 15.75L20.25 9.75"
        stroke="#00A0FB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SalesIcon;
