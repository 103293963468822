import { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseFormReset } from 'react-hook-form';

import { saveUserContactById } from '../../../../api/services/user/userService';
import Translations from '../../../../assets/locale/en/content.json';
import { useToast } from '../../../../components/Toasts/ToastContext';
import { ContactSaveForm, UserFormField } from '../interfaces/ManageUsers';

/**
Custom hook to save the account contact using React Query.
@returns {object} - An object containing saveAccountContact callback function, error, and loading state.
*/

interface useSaveUserContactByIdProps {
  onClose: () => void;
  reset: UseFormReset<UserFormField>;
}

export const useSaveUserContactById = ({ onClose, reset }: useSaveUserContactByIdProps) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  // toast hook to display toast messages
  const { showToast } = useToast();

  // Mutations for updating the data
  const mutation = useMutation({
    mutationFn: async ({ contact, contactId }: { contact: any; contactId: string }) => {
      setLoading(true);
      return await saveUserContactById(contact, contactId);
    },
    onSuccess: () => {
      // Invalidate and refetch the 'GridData' query and 'activeAccountContact'
      queryClient.invalidateQueries({ queryKey: ['GridData'] });
      queryClient.invalidateQueries({ queryKey: ['activeAccountContact'] });
      setLoading(false);
      // display toast for success
      showToast(Translations.common.formSuccesMessage, {
        type: 'success',
      });
      // Reset the form with the saved data to reset the dirty state
      reset();
      // close the form
      onClose();
    },
    onError: () => {
      setLoading(false);
      // display toast for error
      showToast(Translations.common.formErrorMessage, {
        containerId: 'drawer',
        type: 'error',
      });
    },
  });

  // A callback function which we call to save user profile data
  const saveUserContact = useCallback(
    async (contact: ContactSaveForm, contactId: string) => {
      try {
        mutation.mutate({ contact: contact, contactId: contactId! });
      } catch (error: any) {
        setLoading(false);
        throw Error(error.message);
      }
    },
    [mutation],
  );

  return { saveUserContact, loading };
};
