import React, { useState } from 'react';

import { TngButton } from './TngButton';
import { TngGrid } from './TngGrid';
import { TngTypography } from './TngTypography';
import Translations from '../../assets/locale/en/content.json';

interface ToggleSectionProps {
  title: string;
  content: React.ReactNode;
}

export const TngToggleSection: React.FC<ToggleSectionProps> = ({ title, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <TngGrid container className="toggle_section_container">
      <TngGrid item className="toggle_section_heading_grid">
        <TngTypography variant="h5">{title}</TngTypography>
        <TngButton onClick={handleToggle}>
          {isVisible ? Translations.ToggleSection.hideNotes : Translations.ToggleSection.showNotes}
        </TngButton>
      </TngGrid>
      {isVisible && (
        <TngGrid className="toggle_section_body_grid">
          <TngGrid className="toggle_section_body_content">{content}</TngGrid>
        </TngGrid>
      )}
    </TngGrid>
  );
};
