import React from 'react';

import { TngGrid } from './TngGrid';
import { TngTypography } from './TngTypography';
import SadIcon from '../../assets/icons/SadIcon';
import Translations from '../../assets/locale/en/content.json';

const TngNoResultFound = () => {
  return (
    <TngGrid className="no_result_found">
      <SadIcon height="42" width="42" />
      <TngTypography
        role="alert"
        aria-live="assertive"
        data-testid="no_results_message"
        className="custom_message"
      >
        {Translations.common.noOptionsText}
      </TngTypography>
    </TngGrid>
  );
};

export default TngNoResultFound;
