export enum MenuItemEnum {
  DASHBOARD = 'dashboard',
  MARKETING = 'marketing',
  SALES = 'sales',
  MANAGE_CUSTOMERS = 'manage customers',
  COMMUNITY = 'community',
  KNOWLEDGE_CENTER = 'knowledge center',
  SUPPORT = 'support',
  USER_MANAGEMENT = 'user management',
}
