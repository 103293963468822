import React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';

import Translations from '../../../../../assets/locale/en/content.json';
import { TngDivider, TngIconButton, TngListItem } from '../../../../common';

interface NavbarListProps {
  onAccountSettingsClick: () => void;
  onLogoutClick: () => void;
}

const NavbarList: React.FC<NavbarListProps> = ({ onAccountSettingsClick, onLogoutClick }) => {
  return (
    <>
      <TngListItem className="popover_list_item">
        <Link className="popover_list_link" to="/account-settings" onClick={onAccountSettingsClick}>
          {Translations.navbar.accountSettings}
        </Link>
      </TngListItem>
      <TngDivider aria-hidden="true" />
      <TngListItem className="popover_list_item">
        <TngIconButton onClick={onLogoutClick} disableRipple disableTouchRipple disableFocusRipple>
          {Translations.navbar.logout}
          <span>
            <LogoutIcon />
          </span>
        </TngIconButton>
      </TngListItem>
    </>
  );
};

export default NavbarList;
