import { useQuery } from '@tanstack/react-query';

import { fetchUserTypes } from '../../api/services/user/userService';
import { UserTypes } from '../interfaces/user/UserTypes';

/**
Custom hook to fetch the user types using React Query.
@returns {object} - An object containing userTypes data, error, and loading state.
*/
export const useUserTypes = (isAuthenticated: boolean) => {
  const { data, error, isLoading } = useQuery<UserTypes[], Error>({
    queryKey: ['userTypes'],
    queryFn: fetchUserTypes,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
