/**

Mapped the userType data which we get from api to userType dropdown options
@parameter {userTypes} - List of Usertypes.
@returns UserTypeOptions[] - list of usertypes Options for dropdown
*/

import { getAccessibleRolesByUserType } from './rolebaseHelper';
import { UserTypesEmuns } from '../../../../common/enums/UserTypesEmuns';
import { UserTypes, UserTypeOptions } from '../../../../common/interfaces/user/UserTypes';

export const userTypeMapper = (
  userTypes: UserTypes[],
  userRoles: UserTypesEmuns | null,
): UserTypeOptions[] => {
  const accessibleUserTypes = getAccessibleRolesByUserType(userRoles);
  return userTypes
    ?.filter((userType) => accessibleUserTypes.includes(userType.value as UserTypesEmuns))
    .map((userType, index) => ({
      id: index,
      label: userType.label,
      value: userType.value,
    }));
};
