import React, { useEffect, useRef, useState } from 'react';

import { CSSObject, Theme } from '@mui/material';

import ExpandCollapsedIcon from './components/ExpandCollapsedIcon';
import SideMenuList from './components/SideMenuList';
import { useMenu } from './hooks/useMenu';
import { MenuItemModal } from './modal/MenuItemModal';
import { mapMenuData } from './utils/CustomMapping';
import useIsMobile from '../../../../common/hooks/useIsMobile';
import { TngList } from '../../../common';
import Drawer from '../../../Drawer';

interface SideMenuProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu: React.FC<SideMenuProps> = ({ setIsLoading }) => {
  const [sideMenuData, setSideMenuData] = useState<MenuItemModal[]>();
  const [expanded, setExpanded] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(!isMobile);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { data: menuData, isLoading } = useMenu();

  useEffect(() => {
    if (menuData) {
      const mappedData = mapMenuData(menuData);
      setSideMenuData(mappedData);
    }
  }, [menuData]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(isLoading);
    }
  }, [isLoading, setIsLoading]);

  const toggleDrawer = () => {
    if (open) setExpanded(null);
    setOpen(!open);
    // to maintain focus on current button
    // Use a timeout to ensure the state change completes before focusing
    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }, 0);
  };

  const responsiveWidthMixin = (theme: Theme): CSSObject => ({
    [theme.breakpoints.down(1440)]: {
      width: '19%', // Width for screens below 1440px
    },
    [theme.breakpoints.down('md')]: {
      width: '35%', // Width for screens below 850px
    },
    [theme.breakpoints.down(700)]: {
      width: '40%', // Width for screens below 700px
    },
    [theme.breakpoints.down('sm')]: {
      width: '40%', // Width for screens below 600px
    },
    [theme.breakpoints.down(450)]: {
      width: '48%', // Width for screens below 450px
    },
  });

  return (
    <Drawer
      role="navigation"
      id="navigation_landmark"
      className="sidenav_drawer"
      width="18.4%"
      top="64px"
      variant="permanent"
      anchor="left"
      open={open}
      toggleDrawer={toggleDrawer}
      responsiveWidthMixin={responsiveWidthMixin}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <TngList className="sidenav_list_container">
        {sideMenuData && (
          <SideMenuList
            item={sideMenuData}
            open={open}
            setOpen={setOpen}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )}
      </TngList>
      <ExpandCollapsedIcon open={open} toggleDrawer={toggleDrawer} ref={buttonRef} />
    </Drawer>
  );
};

export default SideMenu;
