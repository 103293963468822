import React from 'react';

const TechnicalCommunityIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 18C19.9926 18 21 16.9926 21 15.75C21 14.5074 19.9926 13.5 18.75 13.5C17.5074 13.5 16.5 14.5074 16.5 15.75C16.5 16.9926 17.5074 18 18.75 18Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.25 18C6.49264 18 7.5 16.9926 7.5 15.75C7.5 14.5074 6.49264 13.5 5.25 13.5C4.00736 13.5 3 14.5074 3 15.75C3 16.9926 4.00736 18 5.25 18Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.5 10.5V9.76312C16.5 9.23062 16.2206 8.74088 15.7624 8.46975C15.033 8.03813 13.7685 7.5 12 7.5C10.2086 7.5 8.95237 8.03475 8.2305 8.46563C7.776 8.73713 7.5 9.22538 7.5 9.75488V10.5H16.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.75 23.25V22.5131C9.75 21.9806 9.47063 21.4909 9.01238 21.2197C8.283 20.7881 7.0185 20.25 5.25 20.25C3.45863 20.25 2.20237 20.7848 1.4805 21.2156C1.026 21.4871 0.75 21.9754 0.75 22.5049V23.25H9.75Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23.25 23.25V22.5131C23.25 21.9806 22.9706 21.4909 22.5124 21.2197C21.783 20.7881 20.5185 20.25 18.75 20.25C16.9586 20.25 15.7024 20.7848 14.9805 21.2156C14.526 21.4871 14.25 21.9754 14.25 22.5049V23.25H23.25Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12 5.25C13.2426 5.25 14.25 4.24264 14.25 3C14.25 1.75736 13.2426 0.75 12 0.75C10.7574 0.75 9.75 1.75736 9.75 3C9.75 4.24264 10.7574 5.25 12 5.25Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default TechnicalCommunityIcon;
