import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Translations from '../../../../../assets/locale/en/content.json';
import { CountryOptions } from '../../../../../common/interfaces/CountryOptions';
import { InputFieldConfig } from '../../../../../common/interfaces/InputFieldConfig';
import { StateOptions } from '../../../../../common/interfaces/StateOptions';
import { UserTypeOptions } from '../../../../../common/interfaces/user/UserTypes';
import { countryDropdownMapper } from '../../../../../common/mappers/CountryDropdownMapper';
import { stateDropdownMapper } from '../../../../../common/mappers/StateDropdownMapper';
import { TngGrid, TngLoader } from '../../../../../components/common';
import TngNoResultFound from '../../../../../components/common/TngNoResultFound';
import FieldRendering from '../../../../../components/CustomFormFields/FieldRendering';
import FormButtons from '../../../../../components/CustomFormFields/FormButton';
import { RootState } from '../../../../../store';
import { initializeUserForm } from '../../helper/initializeUserForm';
import { GetInputFieldConfig } from '../../helper/inputFieldConfig';
import { mappedActiveContactToFormData } from '../../helper/mappedActiveContactToFormData';
import { userTypeMapper } from '../../helper/userTypeMapper';
import { useGetAccountContactById } from '../../hook/useGetAccountContactById';
import { useSaveUserContactById } from '../../hook/useSaveAccountContactById';
import { ContactSaveForm, FieldConfig, UserFormField } from '../../interfaces/ManageUsers';

interface AccountContactFormProps {
  onClose: () => void;
}

const AccountContactForm: React.FC<AccountContactFormProps> = ({ onClose }) => {
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const IsEditable = urlObject.searchParams.get('edit');
  const contactId = urlObject.searchParams.get('Id');

  // state to manage default values for form
  const [initialUserValues, setInitialUserValues] = useState<UserFormField>(initializeUserForm());

  // state for page loader
  const [pageLoader, setPageLoader] = useState<boolean>(true);

  // states to store country and state options
  const [countryOptions, setCountryOptions] = useState<CountryOptions[]>([] as CountryOptions[]);
  const [filteredStates, setFilteredStates] = useState<StateOptions[]>([] as StateOptions[]);
  const [userTypeOptions, setUserTypeOptions] = useState<UserTypeOptions[]>(
    [] as UserTypeOptions[],
  );

  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(true);

  // state to store selected country Id
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);

  const { data: contact, isLoading: dataLoad } = useGetAccountContactById(contactId!);
  const userTypes = useSelector((state: RootState) => state.master.userTypes);

  const masterState = useSelector((state: RootState) => state.master);
  const { userRole } = useSelector((state: RootState) => state.auth);
  const { countries, states } = masterState;
  // defining react hook form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    reset,
  } = useForm<UserFormField>({ defaultValues: initialUserValues });

  const { saveUserContact, loading } = useSaveUserContactById({
    onClose,
    reset,
  });

  const fieldConfig: FieldConfig[] = GetInputFieldConfig(
    countryOptions,
    filteredStates,
    userTypeOptions,
    selectedCountry,
  );

  //  to update contact data to its corresponding react state
  useEffect(() => {
    if (IsEditable && contactId && contact && !dataLoad) {
      setIsDataAvailable(contact.length > 0);
      if (contact.length > 0) {
        const contactData = mappedActiveContactToFormData(contact[0], userTypeOptions);
        setInitialUserValues(contactData);
        reset(contactData);
        setPageLoader(dataLoad);
      } else {
        setPageLoader(dataLoad);
      }
    }
  }, [dataLoad, contact, reset, contactId, IsEditable, userTypeOptions]);

  //to update countries to its corresponding react state
  useEffect(() => {
    if (countries.length) {
      const mappedCountries = countryDropdownMapper(countries);
      setCountryOptions(mappedCountries);
    }
  }, [countries]);

  //to update selected country Id on react state as soon as data in there in initialProfileValues.country
  useEffect(() => {
    const userSelectedCountry: string = initialUserValues?.country;
    if (userSelectedCountry) {
      const selectedCountryId =
        countries.length && countries?.find((country) => country.code === userSelectedCountry)?.id;
      if (selectedCountryId) setSelectedCountry(selectedCountryId);
    }
  }, [countries, initialUserValues?.country]);

  //to update states to its corresponding react state
  useEffect(() => {
    if (states.length) {
      const mappedStates = stateDropdownMapper(states, selectedCountry);
      setFilteredStates(mappedStates);
    }
  }, [selectedCountry, states]);

  //to update userTypes to its corresponding react state
  useEffect(() => {
    if (userTypes) {
      const mappedUserTypes = userTypeMapper(userTypes, userRole);
      setUserTypeOptions(mappedUserTypes);
    }
  }, [userRole, userTypes]);

  const dropdownCallback = (field: InputFieldConfig, e: any) => {
    if (field.htmlFor === 'country') {
      setValue('state', '');
      const selectedOption = field.options?.find((option) => option.value === e.target.value)?.id;
      if (selectedOption) setSelectedCountry(selectedOption!); // Call the custom onChange
    }
  };

  // submit handler which save form
  const onSubmit: SubmitHandler<UserFormField> = async (data: UserFormField) => {
    try {
      const {
        firstName,
        lastName,
        jobTitle,
        phoneNumber,
        emailAddress,
        userType,
        country,
        state,
        street,
        city,
        postalCode,
      } = data;
      const savedContact: ContactSaveForm = {
        firstName,
        lastName,
        jobTitle: jobTitle!,
        userType: userType,
        phoneNumber,
        emailAddress,
        address: { countryCode: country, stateCode: state, street, city, postalCode: postalCode! },
      };
      await saveUserContact(savedContact, contactId!);
    } catch (error: any) {
      /* empty */
    }
  };

  return (
    <>
      {pageLoader ? (
        <TngLoader />
      ) : isDataAvailable ? (
        <form aria-label="Edit User" className="edit_user_form" onSubmit={handleSubmit(onSubmit)}>
          <TngGrid className="edit_user_form_fields">
            {fieldConfig.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
              <TngGrid className="form_row" key={rowIndex}>
                <FieldRendering
                  fieldRow={fieldRow}
                  errors={errors}
                  register={register}
                  control={control}
                  dropdownCallback={dropdownCallback}
                />
              </TngGrid>
            ))}
          </TngGrid>
          <FormButtons
            isFormUpdated={isDirty}
            loading={loading}
            btnLabel={Translations.ManageUsers.updateUserDetails}
          />
        </form>
      ) : (
        <TngNoResultFound />
      )}
    </>
  );
};

export default AccountContactForm;
