import React from 'react';

const MarketPlaceIcon = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75 19.5V13.5H13.25V19.5" stroke="#8094AA" strokeMiterlimit="10" />
      <path
        d="M3 12V17C3 18.3807 4.1193 19.5 5.5 19.5H16.5C17.8807 19.5 19 18.3807 19 17V12"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M21.5 6.49945C21.4614 8.1953 20.1187 9.5377 18.4998 9.4992C17.5071 9.5067 16.5736 9.00525 15.9999 8.1563C15.0077 9.6027 13.0842 9.93265 11.7035 8.89325C11.4324 8.68925 11.1947 8.4403 11 8.1563C10.0077 9.6027 8.08435 9.93265 6.7036 8.89325C6.4325 8.68925 6.1948 8.4403 6.0001 8.1563C5.4264 9.00525 4.49285 9.5067 3.50013 9.4992C1.88122 9.5377 0.53866 8.1953 0.5 6.49945L4.3182 0.5H17.6818L21.5 6.49945Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M11 0.75V0.5V8.25V8" stroke="#8094AA" strokeMiterlimit="10" />
      <path d="M14.7903 0.75L14.75 0.5L16 8.25L15.9597 8" stroke="#8094AA" strokeMiterlimit="10" />
      <path d="M7.2097 0.75L7.25 0.5L6 8.25L6.0403 8" stroke="#8094AA" strokeMiterlimit="10" />
    </svg>
  );
};

export default MarketPlaceIcon;
