import { getUsersColumnHeaders } from './columnDetails';
import { getManageUsersUrl } from '../../../../api/urlGenerator';
import Translations from '../../../../assets/locale/en/content.json';
import { GridMoreOptionsEnum } from '../../../../common/enums/GridMoreOptionsEnum';
import { UserTypesEmuns } from '../../../../common/enums/UserTypesEmuns';
import { FindGridConfig } from '../../../../common/interfaces/FindGridConfig';
import { SearchDropdown } from '../../../../common/interfaces/SearchDropdown';
import { ManageUsersGridUrl } from '../../../../common/interfaces/urls/ManageUsersGridUrl';
import { getAccessibleRolesByUserType } from '../../common/helper/rolebaseHelper';

// grid config contains the details about grid which will render for this screen
export const getUsersGridConfig = (
  apiParams: ManageUsersGridUrl,
  accountId: string,
  userRole: UserTypesEmuns,
): FindGridConfig => ({
  baseUrl: getManageUsersUrl(apiParams, accountId),
  navigateToRoute: '/user-details',
  navigateRouteParams: 'Id',
  ColumnHeader: getUsersColumnHeaders(),
  search: {
    isApplicable: true,
    isDropdownApplicable: true,
    defaultSearch: { searchBy: 'FirstName', searchValue: '' },
    searchDropdownOptions: dropDownValues,
    placeholder: Translations.ManageUsers.searchHereFor3CharLimit,
  },
  sorting: {
    isApplicable: false,
  },
  moreOptions: {
    isApplicable: true,
    moreOptionNavigation: [
      {
        label: Translations.ManageUsers.viewMoreDetails,
        navigationTo: 'user-details',
        navigationProps: ['Id'],
        eventType: GridMoreOptionsEnum.VIEW,
      },
      {
        label: Translations.ManageUsers.editUserDetails,
        navigationTo: 'manage-users',
        navigationProps: ['Id'],
        eventType: GridMoreOptionsEnum.EDIT,
        isRoleBased: true,
        userRoles: getAccessibleRolesByUserType(userRole),
      },
    ],
  },
  rowHeight: 75,
  columnWidth: 160,
  isPaginationVisible: true,
});

export const dropDownValues: SearchDropdown[] = [
  { label: 'FirstName', value: Translations.ManageUsers.firstNamePascel },
  { label: 'LastName', value: Translations.ManageUsers.lastNamePascel },
  { label: 'EmailAddress', value: Translations.ManageUsers.emailAddressPascel },
];
