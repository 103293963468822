import { UserTypesEmuns } from '../../../../common/enums/UserTypesEmuns';

export const getAccessibleRolesByUserType = (userType: UserTypesEmuns | null): UserTypesEmuns[] => {
  switch (userType) {
    case UserTypesEmuns.ERP_PPU:
      return [
        UserTypesEmuns.ERP_PPU,
        UserTypesEmuns.ERP_PSU,
        UserTypesEmuns.ERP_GU,
        UserTypesEmuns.ERP_PTU,
        UserTypesEmuns.ERP_GTU,
      ];
    case UserTypesEmuns.ERP_PSU:
      return [UserTypesEmuns.ERP_PSU, UserTypesEmuns.ERP_GU];
    case UserTypesEmuns.ERP_PTU:
      return [UserTypesEmuns.ERP_PTU, UserTypesEmuns.ERP_GTU];
    default:
      return [];
  }
};
