import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

import { NavigationUrl } from '../../../../../common/interfaces/userAdmin/Menu';
import { TngGrid, TngTypography } from '../../../../common';

export const NavigationLink = ({
  navigationUrl,
  itemName,
}: {
  navigationUrl: NavigationUrl;
  itemName: string;
}) => {
  const { url, isSSOEnabled } = navigationUrl;

  return (
    <TngGrid className="navigation_link_grid">
      <TngTypography>{itemName}</TngTypography>
      <Link
        className="navigation_link"
        to={
          isSSOEnabled
            ? url === '/kofaxcommunity'
              ? process.env.REACT_APP_SSO_COMMUNITY_URL!
              : `${process.env.REACT_APP_SSO_URL}${url}`
            : url
        }
        target="_blank"
      >
        <OpenInNewIcon />
      </Link>
    </TngGrid>
  );
};
