import React from 'react';

const ViewSupportCasesIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 17H19.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M8.5 10H19.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M8.5 3H19.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path
        d="M2.75 5.25C3.99265 5.25 5 4.24265 5 3C5 1.75736 3.99265 0.75 2.75 0.75C1.50736 0.75 0.5 1.75736 0.5 3C0.5 4.24265 1.50736 5.25 2.75 5.25Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M2.75 12.25C3.99265 12.25 5 11.2427 5 10C5 8.75735 3.99265 7.75 2.75 7.75C1.50736 7.75 0.5 8.75735 0.5 10C0.5 11.2427 1.50736 12.25 2.75 12.25Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M2.75 19.25C3.99265 19.25 5 18.2427 5 17C5 15.7573 3.99265 14.75 2.75 14.75C1.50736 14.75 0.5 15.7573 0.5 17C0.5 18.2427 1.50736 19.25 2.75 19.25Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ViewSupportCasesIcon;
