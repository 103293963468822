import React from 'react';

const TechSupportIcon = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 14.75V22.5" stroke="#8094AA" strokeMiterlimit="10" />
      <path
        d="M4.5 19.25V21.173L10 22.5L15.5 21.173V19.25M4.5 16.5V13.5L10 14.8271L15.5 13.5V16.5"
        stroke="#8094AA"
        strokeMiterlimit="10"
      />
      <path
        d="M3.99911 16.25L6.47691 17.6954C6.80026 17.884 6.99911 18.2302 6.99911 18.6045C6.99911 19.3039 6.32976 19.8087 5.65736 19.6166L2.26576 18.7974C1.07407 18.5096 0.419456 17.2272 0.885416 16.0932L2.41034 12.3821C3.39081 10.0978 7.94561 9.7909 9.99916 9.75C12.0527 9.7909 16.6075 10.0978 17.588 12.3821L19.1129 16.0932C19.5789 17.2272 18.9243 18.5096 17.7326 18.7974L14.3409 19.6166C13.6685 19.8087 12.9992 19.3039 12.9992 18.6045C12.9992 18.2302 13.198 17.884 13.5214 17.6954L15.9992 16.25"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10 7.25C11.6568 7.25 13 5.90685 13 4.25C13 2.59315 11.6568 1.25 10 1.25C8.34315 1.25 7 2.59315 7 4.25C7 5.90685 8.34315 7.25 10 7.25Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default TechSupportIcon;
