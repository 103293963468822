import pingAxiosInstance from './pingAxiosInstance';
import { ErrorResponse } from '../../../common/interfaces/ErroResponse';
import { TokenCodes } from '../../../common/interfaces/LoginRequest';
import { Tokens } from '../../../common/interfaces/Tokens';
import {
  OtpResponse,
  PasswordRecoveryParams,
  PasswordRecoveryResponse,
  UserCredentials,
} from '../../../common/interfaces/UserAccount';
import store from '../../../store';
import { login, logout } from '../../../store/auth/authSlice';
import { setLoading, setRefreshTokenLoading } from '../../../store/master/masterSlice';
import { URLS, getUrl } from '../../urls';

export const initiateFlow = async (userCredentials: UserCredentials): Promise<TokenCodes> => {
  try {
    const urlParams = {
      clientId: process.env.REACT_APP_CLIENT_ID!,
      // will update this code challenge code in future instead of manual we will create via code
      codeChallenge: 'oVBUs7txXI8x6dysz6fvYPlxlkUkinB-i9EE3rmti4w',
      redirectUri: process.env.REACT_APP_REDIRECT_URL!,
    };
    const url = getUrl(URLS.INITIATE_FLOW, urlParams);
    const response = await pingAxiosInstance.post(url, userCredentials, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (error) {
    const err = JSON.stringify(error);
    throw new Error(err);
  }
};

export const logoutUserService = async (): Promise<void> => {
  try {
    const url = getUrl(URLS.LOGOUT_USER);
    const response = await pingAxiosInstance.get(url);
    if (response.status === 302) {
      const redirectUrl = response.headers.location;
      // Handle the redirection manually in your frontend
      window.location.href = redirectUrl;
    }
  } catch (error: any) {
    if (error.response && error.response.status === 302) {
      const redirectUrl = error.response.headers.location;
      // Handle the redirection manually
      window.location.href = redirectUrl;
    } else {
      // Handle other errors
      console.error('Error:', error);
    }
  }
};

export const validateTokenService = async (token: string): Promise<boolean> => {
  try {
    const url = getUrl(URLS.VALIDATE_TOKEN);
    const response = await pingAxiosInstance.post(url, { token });
    return response.data.isValid;
  } catch (error) {
    throw new Error('Token validation failed');
  }
};

// Function to fetch OTP
export const fetchOTPService = async (flowId: string, username: string): Promise<OtpResponse> => {
  try {
    const url = getUrl(URLS.AUTHENTICATE_USER, { flowId });

    const response = await pingAxiosInstance.post(
      url,
      { username },
      {
        headers: {
          'Content-Type': 'application/vnd.pingidentity.password.forgot+json',
        },
      },
    );

    if (!response.data) {
      throw new Error('No data received');
    }

    return response.data;
  } catch (error: any) {
    const errMessage = error.response?.data?.message || error.message || 'An error occurred';
    throw new Error(errMessage);
  }
};

// Function to recover Password
export const recoverPasswordService = async (
  flowId: string,
  params: PasswordRecoveryParams,
): Promise<PasswordRecoveryResponse> => {
  try {
    const url = getUrl(URLS.AUTHENTICATE_USER, { flowId });

    const response = await pingAxiosInstance.post(url, params, {
      headers: {
        'Content-Type': 'application/vnd.pingidentity.password.recover+json',
      },
    });

    if (!response.data) {
      throw new Error('No data received');
    }

    return response.data;
  } catch (error: any) {
    // sent proper error to component
    const err = error.response.data;
    const { code, message, details } = err;
    const errorResponse: ErrorResponse = {
      responseCode: code,
      responseMessage: message,
      target: '',
    };
    if (details && details.length > 0) {
      const { code, innerError, target, message = '-' } = details[0];
      errorResponse.responseCode = code;
      errorResponse.target = target;
      errorResponse.responseMessage = message;
      if (target === 'newPassword') {
        const errorType = innerError?.unsatisfiedRequirements[0];
        if (innerError[`${errorType}`]) errorResponse.responseMessage = innerError[`${errorType}`];
      }
    }
    const errorMessage = `Code: ${errorResponse.responseCode}, Message: ${errorResponse.responseMessage}, Target: ${errorResponse.target}`;
    throw new Error(errorMessage);
  }
};

// Flag to track ongoing refresh requests
let refreshInProgress = false;
export const setNewRefreshToken = async (refreshToken: string): Promise<Tokens | null> => {
  // Return immediately if a refresh is already in progress

  if (refreshInProgress) {
    console.warn('Token refresh already in progress, ignoring subsequent calls');
    return null;
  }

  refreshInProgress = true;
  store.dispatch(
    setRefreshTokenLoading({
      isRefreshTokenLoading: true,
    }),
  );
  store.dispatch(setLoading({ isLoading: true }));

  try {
    const urlParams = new URLSearchParams();
    urlParams.append('grant_type', 'refresh_token');
    urlParams.append('refresh_token', refreshToken);
    urlParams.append('client_id', process.env.REACT_APP_CLIENT_ID!);
    const url = getUrl(URLS.REFRESH_TOKEN);
    const response = await pingAxiosInstance.post(url, urlParams, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const { access_token, id_token, refresh_token } = response.data;

    if (access_token && id_token && refresh_token) {
      // Dispatch new tokens to the store
      store.dispatch(
        login({
          token: access_token,
          refreshToken: refresh_token,
        }),
      );
      store.dispatch(
        setRefreshTokenLoading({
          isRefreshTokenLoading: false,
        }),
      );
      return { accessToken: access_token }; // Return both tokens
    }

    return null; // Explicit return if no other condition is met
  } catch (error) {
    handleLogout();
    return null;
  } finally {
    refreshInProgress = false; // Reset the flag once the request completes
    store.dispatch(
      setRefreshTokenLoading({
        isRefreshTokenLoading: false,
      }),
    );
  }
};

// Consolidated logout and redirect function
const handleLogout = () => {
  store.dispatch(logout());
  window.location.href = '/';
};
