import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { RenderMenuLinks } from './RenderMenuLinks';
import { TngGrid, TngList } from '../../../../common';
import { MenuItemModal } from '../modal/MenuItemModal';

interface SideMenuListProps {
  item: MenuItemModal[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: string | null;
  setExpanded: React.Dispatch<React.SetStateAction<string | null>>;
}

const SideMenuList: React.FC<SideMenuListProps> = ({
  item,
  open,
  setOpen,
  expanded,
  setExpanded,
}) => {
  const location = useLocation();

  const handleAccordionToggle = (itemName: string, hasChildItems: boolean, isParent: boolean) => {
    if (hasChildItems) {
      if (!open) setOpen(true);
      setExpanded(expanded === itemName ? null : itemName);
    } else {
      if (isParent) {
        setExpanded(null);
      }
    }
  };
  const isLinkActive = (activeItems: string[]) => {
    const segments = location.pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    return activeItems.includes(`/${lastSegment}`);
  };

  const RenderItems = (items: MenuItemModal[]) => {
    return items.map((item) => {
      const hasChildItems = item.items && item.items.length > 0;
      return (
        <TngGrid
          key={item.itemName}
          className={`side_menu_list_item  ${!open ? 'side_menu_close' : ''} ${(isLinkActive(item.activeItems) || expanded === item.itemName) && 'active_panel'}`}
        >
          {/* Render Accordion regardless of child existence */}
          <Accordion
            expanded={hasChildItems && expanded === item.itemName} // Only expand if it has children
            onChange={() => handleAccordionToggle(item.itemName, hasChildItems, item.isParent)}
            className="side_menu_accordian"
          >
            <AccordionSummary
              expandIcon={hasChildItems ? <ExpandMoreIcon /> : null} // No expand icon if no children
              className="accordian_summary"
            >
              <RenderMenuLinks item={item} />
            </AccordionSummary>
            {hasChildItems && (
              <AccordionDetails className="accordian_details">
                <TngList className="side_menu_list_item_child">{RenderItems(item.items)}</TngList>
              </AccordionDetails>
            )}
          </Accordion>
        </TngGrid>
      );
    });
  };

  return <TngList className="side_menu_list">{RenderItems(item)}</TngList>;
};

export default SideMenuList;
