import React from 'react';

const DashboardIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 10H0.5C0.2 10 0 10.2 0 10.5V17.5C0 17.8 0.2 18 0.5 18H7.5C7.8 18 8 17.8 8 17.5V10.5C8 10.2 7.8 10 7.5 10ZM7 17H1V11H7V17ZM7.5 0H0.5C0.2 0 0 0.2 0 0.5V7.5C0 7.8 0.2 8 0.5 8H7.5C7.8 8 8 7.8 8 7.5V0.5C8 0.2 7.8 0 7.5 0ZM7 7H1V1H7V7ZM17.5 0H10.5C10.2 0 10 0.2 10 0.5V7.5C10 7.8 10.2 8 10.5 8H17.5C17.8 8 18 7.8 18 7.5V0.5C18 0.2 17.8 0 17.5 0ZM17 7H11V1H17V7ZM17.5 13.5H14.5V10.5C14.5 10.2 14.3 10 14 10C13.7 10 13.5 10.2 13.5 10.5V13.5H10.5C10.2 13.5 10 13.7 10 14C10 14.3 10.2 14.5 10.5 14.5H13.5V17.5C13.5 17.8 13.7 18 14 18C14.3 18 14.5 17.8 14.5 17.5V14.5H17.5C17.8 14.5 18 14.3 18 14C18 13.7 17.8 13.5 17.5 13.5Z"
        fill="#00A0FB"
      />
    </svg>
  );
};

export default DashboardIcon;
