import React from 'react';

import { useSelector } from 'react-redux';

import AccountDetails from './CompanyAccountDetails/AccountDetails';
import Profile from './MyProfile/Profile';
import Translations from '../../../assets/locale/en/content.json';
import { CustomTabs } from '../../../components/CustomTab/CustomTabs';
import { RootState } from '../../../store';

interface TabConfig {
  label: string;
  id: number;
}

const AccountSettingsTabs = () => {
  const { accountDetail, userProfile } = useSelector((state: RootState) => state.user.permissions);

  const permissionConfig = [
    { label: Translations.accountSettings.profile, id: 1, view: userProfile?.view },
    { label: Translations.accountSettings.accountDetails, id: 2, view: accountDetail?.view },
  ];

  // Filter out the tabs that should be visible based on the 'view' permission
  const tabConfig: TabConfig[] = permissionConfig
    .filter((item) => item.view) // Only include items where 'view' is true
    .map((item) => ({ label: item.label, id: item.id })); // Map to the required format

  return (
    <CustomTabs tabs={tabConfig} orientation="vertical">
      {/* Conditionally render components based on available tabs */}
      {userProfile?.view && <Profile />}
      {accountDetail?.view && <AccountDetails />}
    </CustomTabs>
  );
};

export default AccountSettingsTabs;
