import { Icons, IconsAPIResponse } from '../../../common/interfaces/userAdmin/Icons';
import { Menu, MenuAPIResponse } from '../../../common/interfaces/userAdmin/Menu';
import { PermissionItem } from '../../../common/interfaces/userAdmin/Permissions';
import { UserPermissionsApiRes } from '../../../common/interfaces/userAdmin/UserPermissionsApiRes';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

export const fetchUserPermissions = async (): Promise<PermissionItem[]> => {
  try {
    const url = getUrl(URLS.USER_PERMISSIONS);
    const { data } = await axiosInstance.get<UserPermissionsApiRes>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch user permissions');
  }
};

export const fetchIcons = async (): Promise<Icons[]> => {
  try {
    const url = getUrl(URLS.ICONS);
    const { data } = await axiosInstance.get<IconsAPIResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch icons');
  }
};

export const fetchMenus = async (): Promise<Menu[]> => {
  try {
    const url = getUrl(URLS.MENUS);
    const { data } = await axiosInstance.get<MenuAPIResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch menu');
  }
};
