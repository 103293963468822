import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { fetchPartnerProgramGuideNames } from '../../../api/services/partnerProgramGuide/partnerProgramGuideService';
import { ProgramGuideSectionNames } from '../../../common/interfaces/partnerProgramGuide';
import { RootState } from '../../../store';

export const usePartnerProgramNames = () => {
  const { accountId } = useSelector((state: RootState) => state.user.user);
  const { data, error, isLoading } = useQuery<ProgramGuideSectionNames[], Error>({
    queryKey: ['partnerProgramNames'],
    queryFn: () => fetchPartnerProgramGuideNames(accountId),
  });
  return { data, error, isLoading };
};
