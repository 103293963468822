import React, { ReactNode, useState } from 'react';

import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import SideMenu from './SideMenu';
import { TngGrid, TngLoader } from '../../common';

interface LayoutProps {
  children?: ReactNode;
}

const DashboardLayout: React.FC<LayoutProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  return (
    <TngGrid className="layout_container">
      <Navbar />
      {isLoading && (
        <TngGrid className="custom-loader-overlay">
          <TngLoader />
        </TngGrid>
      )}
      <TngGrid className={`dashboard_container ${isLoading ? 'hidden_outlet' : ''}`}>
        <SideMenu setIsLoading={setIsLoading} />
        <Outlet />
      </TngGrid>
    </TngGrid>
  );
};

export default React.memo(DashboardLayout);
