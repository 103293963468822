import React from 'react';

const MarketingIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.74975 16.25L11.2565 20.7695C11.4995 21.4977 10.9572 22.25 10.1892 22.25H8.81075C8.32625 22.25 7.8965 21.9403 7.7435 21.4805L5 13.25"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M5.75 13.25V3.5" stroke="#00A0FB" strokeMiterlimit="10" />
      <path
        d="M20 10.4935C20.8737 10.1845 21.5 9.35125 21.5 8.37175C21.5 7.39225 20.8737 6.559 20 6.25"
        stroke="#00A0FB"
        strokeMiterlimit="10"
      />
      <path
        d="M17.75 16.25H16.25C16.25 16.25 15.5 13.25 10.25 13.25H5.375C2.6825 13.25 0.5 11.0675 0.5 8.375C0.5 5.6825 2.6825 3.5 5.375 3.5H10.25C15.5 3.5 16.25 0.5 16.25 0.5H17.75V16.25Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default MarketingIcon;
