import React from 'react';

const DemoCenterIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 6.5H19.5C20.0304 6.5 20.5391 6.71071 20.9142 7.08579C21.2893 7.46086 21.5 7.96957 21.5 8.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5H2.5C1.96957 21.5 1.46086 21.2893 1.08579 20.9142C0.710714 20.5391 0.5 20.0304 0.5 19.5V8.5C0.5 7.96957 0.710714 7.46086 1.08579 7.08579C1.46086 6.71071 1.96957 6.5 2.5 6.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.5 11.5L13.5 14L9.5 16.5V11.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M18.5 3.5H3.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M16.5 0.5H5.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export default DemoCenterIcon;
