import React from 'react';

import { CircularProgress } from '@mui/material';

import { TngGrid } from './TngGrid';

export const TngLoader = () => {
  return (
    <TngGrid className="custom-loader" data-testid="custom-loader">
      <CircularProgress role="progressbar" />
    </TngGrid>
  );
};
