import React, { forwardRef } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { TngGrid, TngListItemButton } from '../../../../common';

interface ExpandCollapsedIconProps {
  open: boolean;
  toggleDrawer: () => void;
}

const ExpandCollapsedIcon = forwardRef<HTMLButtonElement, ExpandCollapsedIconProps>(
  ({ open, toggleDrawer }, ref) => {
    return (
      <TngGrid className={`drawer_icon_container ${!open ? 'sidenav_drawer_icon_closed' : ''}`}>
        <TngListItemButton
          component="button"
          aria-label={open ? 'Menu Collapsed Icon' : 'Menu Expand Icon'}
          onClick={toggleDrawer}
          className="sidenav_drawer_icon"
          id="sidenav_expand_collapsed_button"
          ref={ref}
          disableRipple
          disableTouchRipple
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </TngListItemButton>
      </TngGrid>
    );
  },
);

ExpandCollapsedIcon.displayName = 'ExpandCollapsedIcon';

export default ExpandCollapsedIcon;
