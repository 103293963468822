import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import UserInfo from './components/UserInfo';
import { UserDetailsModal } from './modals/UserDetailsModal';
import { dataMapper } from './utils/DataMapper';
import Translations from '../../../assets/locale/en/content.json';
import { UserTypesEmuns } from '../../../common/enums/UserTypesEmuns';
import { TngGrid, TngLoader } from '../../../components/common';
import TngBackNavigation from '../../../components/common/TngBackNavigation';
import TngNoResultFound from '../../../components/common/TngNoResultFound';
import { RootState } from '../../../store';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';
import EditUserDrawer from '../common/components/AccountContactFrom/EditFormDrawer';
import { getAccessibleRolesByUserType } from '../common/helper/rolebaseHelper';
import { useGetAccountContactById } from '../common/hook/useGetAccountContactById';

const UserDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetailsModal>();
  const [pageLoader, setPageLoader] = useState(true);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const contactId = new URLSearchParams(search).get('Id');
  const isEditMode = new URLSearchParams(search).get('edit') === 'true';

  // extract userRole and userTypes from redux store
  const { userRole } = useSelector((state: RootState) => state.auth);
  const userTypes = useSelector((state: RootState) => state.master.userTypes);

  // get all the roles who can edit the user
  const accessibleRoles = useMemo(() => getAccessibleRolesByUserType(userRole), [userRole]);

  // get the role of current contact/user from userDetails
  const currentUserRole = useMemo(() => {
    const userType = userTypes.find((user) => user.label === userDetails?.userType);
    return userType?.value as UserTypesEmuns;
  }, [userDetails?.userType, userTypes]);

  // check if logged in user can have access to currect open contact
  const isEditAccessible = accessibleRoles.includes(currentUserRole);

  const { data: contact, isLoading: isContactLoading } = useGetAccountContactById(contactId!);

  useEffect(() => {
    if (contact && contactId) {
      if (contact.length > 0) {
        const { userDetailsInfo } = dataMapper(contact[0]);
        setUserDetails(userDetailsInfo);
      }
    }
    setPageLoader(isContactLoading);
  }, [contact, contactId, isContactLoading]);

  useEffect(() => {
    focusFirstInteractiveElement(document.getElementById('main-content'));
  }, []);

  useEffect(() => {
    setIsEditUser(isEditMode && !!contactId);
  }, [isEditMode, contactId]);

  const handleEditClose = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('edit');
    navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
    setIsEditUser(false);
  };

  return (
    <TngGrid container className="user_detail_parent_container">
      <TngBackNavigation
        label={Translations.common.back}
        path={Translations.navigation.activeUsers}
      />
      <TngGrid container className="user_detail_child_container">
        {pageLoader ? (
          <TngLoader />
        ) : userDetails ? (
          <UserInfo userDetails={userDetails} isEditAccessible={isEditAccessible} />
        ) : (
          <TngNoResultFound />
        )}
      </TngGrid>
      {isEditUser && isEditAccessible && (
        <EditUserDrawer open={isEditUser} onClose={handleEditClose} />
      )}
    </TngGrid>
  );
};

export default UserDetails;
