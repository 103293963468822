import React from 'react';

import { Card, CardContent } from '@mui/material';

import { TngButton } from './TngButton';
import { TngGrid } from './TngGrid';
import { TngTypography } from './TngTypography';
import SadIcon from '../../assets/icons/SadIcon';
import Translations from '../../assets/locale/en/content.json';

interface ErrorFallbackProps {
  code?: string;
  errorHeading?: string;
  errorMessage?: string;
}

const TngErrorFallbackUI: React.FC<ErrorFallbackProps> = ({ code, errorHeading, errorMessage }) => {
  return (
    <TngGrid container className="error_parent_container">
      <TngGrid className="error_child_container">
        <Card>
          <CardContent>
            <TngGrid className="error_card_details">
              <SadIcon height="45" width="45" />
              {code && <TngTypography className="error_code">{code}</TngTypography>}
              {errorHeading && (
                <TngTypography className="error_heading">{errorHeading}</TngTypography>
              )}
              {errorMessage && (
                <TngTypography className="error_message">{errorMessage}</TngTypography>
              )}
              <TngButton onClick={() => (window.location.href = '/dashboard')}>
                {Translations.common.backToDashboard}
              </TngButton>
            </TngGrid>
          </CardContent>
        </Card>
      </TngGrid>
    </TngGrid>
  );
};

export default TngErrorFallbackUI;
