import { useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import MoreIcon from '../../../../assets/icons/MoreIcon';
import Translations from '../../../../assets/locale/en/content.json';
import { GridMoreOptionsEnum } from '../../../../common/enums/GridMoreOptionsEnum';
import { UserTypesEmuns } from '../../../../common/enums/UserTypesEmuns';
import { NavigationOptions } from '../../../../common/interfaces/GridMoreOptionsConfig';
import { RootState } from '../../../../store';
import { TngGrid, TngIconButton, TngList, TngListItem, TngPopover } from '../../../common';

const MoreOption = (props: any) => {
  const { moreOptionNavigation, data } = props;
  const userTypes = useSelector((state: RootState) => state.master.userTypes);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const addParamToURL = (absoluteURL: URL, navigationProps: string[]) => {
    navigationProps.forEach((prop: string) => {
      const value = props.data[prop];
      if (value !== undefined) {
        absoluteURL.searchParams.set(prop, value);
      }
    });
  };

  const createAbsoluteUrl = (nav: NavigationOptions) => {
    let absoluteURL: URL;

    // Use switch case for eventType and then create the URL to navigate
    switch (nav.eventType) {
      // For VIEW, change the base URL according to navigation props
      case GridMoreOptionsEnum.VIEW:
        absoluteURL = new URL(nav.navigationTo, window.location.origin);
        if (nav.navigationProps && nav.navigationProps.length > 0) {
          addParamToURL(absoluteURL, nav.navigationProps);
        }
        break;
      // For EDIT, add edit=true as a param before adding Id and don't change the base URL
      case GridMoreOptionsEnum.EDIT:
        absoluteURL = new URL(window.location.href);
        absoluteURL.searchParams.set('edit', 'true');
        if (nav.navigationProps && nav.navigationProps.length > 0) {
          addParamToURL(absoluteURL, nav.navigationProps);
        }
        break;

      // For DUMMY, just don't navigate anywhere
      case GridMoreOptionsEnum.DUMMY:
        absoluteURL = new URL(window.location.href);
        break;

      // Default case: similar to VIEW but with fallback URL
      default:
        absoluteURL = new URL(nav.navigationTo, window.location.origin);
        if (nav.navigationProps && nav.navigationProps.length > 0) {
          addParamToURL(absoluteURL, nav.navigationProps);
        }
    }

    // if any navigation Tab is provided then append this tab at last of the url
    if (nav?.navigationTab && nav.navigationTab.length > 0) {
      nav.navigationTab.forEach((tab) => {
        absoluteURL.searchParams.set(tab.name, tab.value);
      });
    }

    return absoluteURL;
  };

  const getValueByLabel = (label: string): UserTypesEmuns =>
    userTypes.find((user) => user.label === label)?.value as UserTypesEmuns;

  return (
    <TngGrid className="more_option_container" style={{ position: 'relative' }}>
      <TngIconButton
        disableRipple
        disableFocusRipple
        onClick={handleClick}
        className="more_option_btn"
        role="button"
        data-testid="more-option-button"
        aria-label={Translations.orderHistory.moreOptionButton}
      >
        <MoreIcon height="18px" width="18px" />
      </TngIconButton>
      <TngPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="more_option_popup"
        data-testid="more-option-popup"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <TngList className="more_option_list">
          {moreOptionNavigation.map((nav: NavigationOptions) => {
            const absoluteURL = createAbsoluteUrl(nav);
            const userRole = getValueByLabel(data.UserType);
            return nav?.isRoleBased && !nav?.userRoles?.includes(userRole) ? null : (
              <TngListItem key={nav.label} className="more_option_list_items">
                <Link to={absoluteURL.href}>{nav.label}</Link>
              </TngListItem>
            );
          })}
        </TngList>
      </TngPopover>
    </TngGrid>
  );
};

export default MoreOption;
