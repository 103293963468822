import { useEffect } from 'react';

import TrainingCertificationGrid from './components/TrainingCertificationGrid';
import Translations from '../../assets/locale/en/content.json';
import { GridContextProvider } from '../../common/GridContext/GridContextProvider';
import { TngGrid, TngTypography } from '../../components/common';
import '../../assets/styles/components/TrainingCertifications/index.scss';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const TrainingCertification = () => {
  useEffect(() => {
    const mainDiv = document.getElementById('header_container');
    focusFirstInteractiveElement(mainDiv);
  });
  return (
    <GridContextProvider>
      <TngGrid container className="training_certification_parent_container" id="header_container">
        <TngGrid container className="training_certification_children_container">
          <TngGrid item xs={12} className="training_certification_heading">
            <TngTypography variant="h3">
              {Translations.technicalCertifications.technicalCertificationsHeading}
            </TngTypography>
          </TngGrid>
          <TrainingCertificationGrid />
        </TngGrid>
      </TngGrid>
    </GridContextProvider>
  );
};

export default TrainingCertification;
