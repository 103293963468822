import { getColumnHeaders } from './columnDetails';
import { getTrainingCertificationListUrl } from '../../../api/urlGenerator';
import Translations from '../../../assets/locale/en/content.json';
import { FindGridConfig } from '../../../common/interfaces/FindGridConfig';

// grid config contains the details about grid which will render for this screen
export const getGridConfig = (
  apiParams: any,
  accountId: string,
  isContactVisible: boolean,
): FindGridConfig => ({
  baseUrl: getTrainingCertificationListUrl(apiParams, accountId),
  ColumnHeader: getColumnHeaders(isContactVisible),
  search: {
    isApplicable: true,
    defaultSearch: { searchBy: '', searchValue: '' },
    isDropdownApplicable: false,
    placeholder: Translations.technicalCertifications.placeholderText,
  },
  sorting: {
    isApplicable: false,
  },
  moreOptions: {
    isApplicable: false,
    moreOptionNavigation: [],
  },
  rowHeight: 75,
  columnWidth: 160,
  isPaginationVisible: true,
});
