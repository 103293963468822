import React from 'react';

const PriceListIcon = () => {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 23L1 21.5V2.5L17 1V23Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M20 3V21" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M4.5 14.5H12.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M4.5 18L12.5 18.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path
        d="M8.5 11C10.1569 11 11.5 9.65685 11.5 8C11.5 6.34315 10.1569 5 8.5 5C6.84315 5 5.5 6.34315 5.5 8C5.5 9.65685 6.84315 11 8.5 11Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default PriceListIcon;
