import React from 'react';

const NewSupportRequestIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 15.5C6.24265 15.5 7.25 14.4927 7.25 13.25C7.25 12.0073 6.24265 11 5 11C3.75736 11 2.75 12.0073 2.75 13.25C2.75 14.4927 3.75736 15.5 5 15.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 15.5C18.2427 15.5 19.25 14.4927 19.25 13.25C19.25 12.0073 18.2427 11 17 11C15.7573 11 14.75 12.0073 14.75 13.25C14.75 14.4927 15.7573 15.5 17 15.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17.25 1H9.25C8.8358 1 8.5 1.33579 8.5 1.75V7.4V9L10.875 7.4H17.25C17.6642 7.4 18 7.0642 18 6.65V1.75C18 1.33579 17.6642 1 17.25 1Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.00028 17.5C2.77054 17.5 0.91956 19.1217 0.5625 21.25L1.7029 21.507C3.87394 21.9962 6.12663 21.9962 8.29768 21.507L9.43808 21.25C9.08103 19.1217 7.23003 17.5 5.00028 17.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.9378 17.5C14.708 17.5 12.8571 19.1217 12.5 21.25L13.6404 21.507C15.8115 21.9962 18.0641 21.9962 20.2351 21.507L21.3755 21.25C21.0185 19.1217 19.1675 17.5 16.9378 17.5Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default NewSupportRequestIcon;
