import React, { useEffect, useState } from 'react';

import { Card, CardContent } from '@mui/material';
import { useSelector } from 'react-redux';

import AccountInfo from './components/AccountInfo';
import AddressInfo from './components/AddressInfo';
import PartnerLevel from './components/PartnerLevel';
import { useAccountDetails } from './hooks/useAccountDetails';
import { AccountInfoDetails } from './modals/AccountInfoDetails';
import { AddressInfoDetails } from './modals/AddressInfoDetails';
import { PartnerLevelDetails } from './modals/PartnerLevelDetails';
import { dataMapper } from './utils/DataMapper';
import Translations from '../../../../assets/locale/en/content.json';
import { TngGrid, TngLoader, TngTypography } from '../../../../components/common';
import { RootState } from '../../../../store';

const AccountDetails = () => {
  // Maintaining state for Account, partnerlevel, and address info.
  const [accountInfo, setAccountInfo] = useState<AccountInfoDetails>();
  const [partnerLevel, setPartnerLevel] = useState<PartnerLevelDetails>();
  const [addressInfo, setAddressInfo] = useState<AddressInfoDetails>();
  const [loader, setLoader] = useState<boolean>(true);
  // For now hard coding value of account Id. After will be fetching from redux.
  const { accountId } = useSelector((state: RootState) => state.user.user);
  const { data } = useAccountDetails(accountId);

  useEffect(() => {
    if (data) {
      const { accountInfo, partnerInfo, addressInfo } = dataMapper(data);
      setAccountInfo(accountInfo);
      setPartnerLevel(partnerInfo);
      setAddressInfo(addressInfo);
      setLoader(false);
    }
  }, [data]);

  return (
    <TngGrid container className="account_details_container" marginBottom="100px">
      <TngGrid item xs={10} md={10} lg={12}>
        <Card>
          <TngTypography variant="h5" className="account_details_main_heading">
            {Translations.accountSettings.accountDetails}
          </TngTypography>
          <CardContent>
            {loader ? (
              <TngLoader />
            ) : (
              <TngGrid container className="account_details_content_grid">
                <TngGrid item xs={12}>
                  <AccountInfo accountInfo={accountInfo} />
                </TngGrid>
                <TngGrid item xs={12}>
                  <PartnerLevel partnerInfo={partnerLevel} />
                </TngGrid>
                <TngGrid item xs={12}>
                  <AddressInfo addressInfo={addressInfo} />
                </TngGrid>
              </TngGrid>
            )}
          </CardContent>
        </Card>
      </TngGrid>
    </TngGrid>
  );
};

export default AccountDetails;
