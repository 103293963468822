import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  fetchEndUserCustomers,
  fetchSoldThroughCustomers,
} from '../../../api/services/customer/customerService';
import { RootState } from '../../../store';

export const useCustomers = () => {
  const [error, setError] = useState<string | null>(null);
  const { accountId } = useSelector((state: RootState) => state.user.user);
  // A callback function which we call to fetch customers
  const getCustomers = useCallback(
    async (
      { isSoldThroughCustomer = false }: { isSoldThroughCustomer?: boolean } = {},
      customerName: string,
    ) => {
      try {
        if (isSoldThroughCustomer) {
          const data = await fetchSoldThroughCustomers(customerName, accountId);
          return data;
        } else {
          const data = await fetchEndUserCustomers(customerName, accountId);
          return data;
        }
      } catch (error: any) {
        setError('Failed to fetch customers');
        throw Error(error.message);
      }
    },
    [],
  );

  return { getCustomers, error };
};
