import { useQuery } from '@tanstack/react-query';

import { fetchMenus } from '../../../../../api/services/userAdmin/userAdminService';
import { Menu } from '../../../../../common/interfaces/userAdmin/Menu';

/**
Custom hook to fetch the menu using React Query.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useMenu = () => {
  const { data, error, isLoading } = useQuery<Menu[], Error>({
    queryKey: ['menu'],
    queryFn: fetchMenus,
  });
  return { data, error, isLoading };
};
