import React from 'react';

const UserManagementIcon = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.26185 15.2218V16.4314C6.26185 17.7367 3.81198 18.0993 2.80181 18.5112C1.73512 18.8154 1 19.786 1 20.8904V21.5H16V20.8904C16 19.786 15.2649 18.8154 14.1982 18.5112C13.188 18.0993 10.7381 17.7367 10.7381 16.4314V15.3125C12.1623 15.197 13.1023 14.6822 14.0399 13.8258C12.5892 12.2014 12.6447 10.3625 12.6447 9.125C12.6447 6.84685 10.7891 5 8.5 5C6.21095 5 4.35527 6.84685 4.35527 9.125C4.35527 10.3625 4.4109 12.2014 2.96024 13.8258C3.89778 14.6822 4.83771 15.1063 6.26185 15.2218Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17.5005 17.5H23.0005V16.02C23.0005 15.3798 22.5941 14.8137 21.9909 14.5991C20.5456 14.0851 18.3917 13.2516 17.7223 12.5265C17.5863 12.3791 17.5522 12.1745 17.5522 11.974V11.0316C18.4414 10.4376 19.0826 9.50535 19.2707 8.4042L19.7383 5.6676C20.2005 2.96251 18.0619 0.5 15.2505 0.5C13.4278 0.5 11.888 1.53495 11.1562 3"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default UserManagementIcon;
