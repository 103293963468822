import { Link } from 'react-router-dom';

import { IconComponent } from './IconComponent';
import { NavigationLink } from './NavigationLink';
import { TngGrid, TngIconButton, TngTypography } from '../../../../common';
import { MenuItemModal } from '../modal/MenuItemModal';

export const RenderMenuLinks = ({ item }: { item: MenuItemModal }) => {
  const { navigationUrl, routeRelativePath, itemName, iconId } = item;

  return (
    <TngGrid className="menu_links_section">
      {navigationUrl ? (
        <>
          <IconComponent iconId={iconId} />
          <NavigationLink navigationUrl={navigationUrl} itemName={itemName} />
        </>
      ) : routeRelativePath ? (
        <Link to={routeRelativePath} className="route_relative_link">
          <TngIconButton>
            <IconComponent iconId={iconId} />
          </TngIconButton>
          <span className="route_relative_link_text">{itemName}</span>
        </Link>
      ) : (
        <>
          <IconComponent iconId={iconId} />
          <TngTypography className="normal_heading">{itemName}</TngTypography>
        </>
      )}
    </TngGrid>
  );
};
