import React from 'react';

const TechnicalCertificationIcon = () => {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 5V3C21.5 1.61929 20.3807 0.5 19 0.5H3C1.61929 0.5 0.5 1.61929 0.5 3V15C0.5 16.3807 1.61929 17.5 3 17.5H11.5"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 13C18.1046 13 19 12.1046 19 11C19 9.89545 18.1046 9 17 9C15.8954 9 15 9.89545 15 11C15 12.1046 15.8954 13 17 13Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M19.25 15L20 19.5L17 18.75L14 19.5L14.75 15"
        stroke="#8094AA"
        strokeMiterlimit="10"
      />
      <path
        d="M22 11C22 10.2742 21.5716 9.6526 20.9566 9.3611C21.1853 8.72005 21.0488 7.9777 20.5355 7.46445C20.0223 6.9512 19.28 6.8146 18.6389 7.04335C18.3474 6.42835 17.7258 6 17 6C16.2742 6 15.6526 6.42835 15.3611 7.04335C14.72 6.81465 13.9777 6.9512 13.4644 7.46445C12.9512 7.9777 12.8147 8.72005 13.0434 9.3611C12.4284 9.6526 12 10.2742 12 11C12 11.7258 12.4284 12.3474 13.0434 12.6389C12.8147 13.2799 12.9512 14.0222 13.4644 14.5355C13.9777 15.0488 14.7201 15.1854 15.3611 14.9566C15.6526 15.5716 16.2742 16 17 16C17.7258 16 18.3474 15.5716 18.6389 14.9566C19.2799 15.1854 20.0222 15.0488 20.5355 14.5355C21.0488 14.0222 21.1854 13.2799 20.9566 12.6389C21.5716 12.3474 22 11.7258 22 11Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M5 5H8.5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M12 5H11" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M6.5 8H5" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export default TechnicalCertificationIcon;
