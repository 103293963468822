import React from 'react';

const ManageCustomerIcon = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 5C5.2431 5 6.25 3.99312 6.25 2.75C6.25 1.50688 5.2431 0.5 4 0.5C2.75688 0.5 1.75 1.50688 1.75 2.75C1.75 3.99312 2.75688 5 4 5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
      />
      <path
        d="M20 5C18.7569 5 17.75 3.99312 17.75 2.75C17.75 1.50688 18.7569 0.5 20 0.5C21.2431 0.5 22.25 1.50688 22.25 2.75C22.25 3.99312 21.2431 5 20 5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
      />
      <path
        d="M17.75 15.9974L18.5 21.4974H21.7989L22.5975 9.11332C22.6772 7.87667 21.6247 6.86587 20.3922 6.99562L19.2178 7.11922C18.4704 7.19792 17.8302 7.68887 17.5604 8.39027L16.8324 10.2832C16.7801 10.4192 16.6711 10.5256 16.5339 10.5746L13.8513 11.5327C13.4907 11.6615 13.25 12.003 13.25 12.3859C13.25 12.9344 13.7336 13.3571 14.2772 13.2838L18.25 12.7474"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.25014 15.9974L5.40414 21.4974H2.20122L1.40269 9.11332C1.32295 7.87667 2.37551 6.86587 3.60792 6.99562L4.78238 7.11922C5.52974 7.19792 6.16994 7.68887 6.43969 8.39027L7.16774 10.2832C7.22004 10.4192 7.32904 10.5256 7.46624 10.5746L10.1488 11.5327C10.5094 11.6615 10.7501 12.003 10.7501 12.3859C10.7501 12.9344 10.2665 13.3571 9.72294 13.2838L5.75014 12.7474"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ManageCustomerIcon;
