import React from 'react';

const CommunityIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 1H11V11.5L15.5 8H23V1Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10 21.836C10 21.232 9.7355 20.6575 9.262 20.282C8.539 19.708 7.285 19 5.5 19C3.715 19 2.461 19.708 1.738 20.282C1.2645 20.6575 1 21.232 1 21.836V23H10V21.836Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.5 16.5C6.88071 16.5 8 15.3807 8 14C8 12.6193 6.88071 11.5 5.5 11.5C4.11929 11.5 3 12.6193 3 14C3 15.3807 4.11929 16.5 5.5 16.5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23 21.836C23 21.232 22.7355 20.6575 22.262 20.282C21.539 19.708 20.285 19 18.5 19C16.715 19 15.461 19.708 14.738 20.282C14.2645 20.6575 14 21.232 14 21.836V23H23V21.836Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.5 16.5C19.8807 16.5 21 15.3807 21 14C21 12.6193 19.8807 11.5 18.5 11.5C17.1193 11.5 16 12.6193 16 14C16 15.3807 17.1193 16.5 18.5 16.5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CommunityIcon;
