import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { fetchContacts } from '../../../api/services/trainingcertification/TrainingCertificationService';
import { Contact } from '../../../common/interfaces/contacts/Contacts';
import { RootState } from '../../../store';

export const useContacts = () => {
  const { accountId } = useSelector((state: RootState) => state.user.user);
  return useQuery<Contact[], Error>({
    queryKey: ['contacts', accountId],
    queryFn: () => fetchContacts(accountId),
  });
};
