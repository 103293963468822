import { useQuery } from '@tanstack/react-query';

import { fetchIcons } from '../../api/services/userAdmin/userAdminService';
import { Icons } from '../interfaces/userAdmin/Icons';

/**
Custom hook to fetch all icons using React Query.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useIcons = (isAuthenticated: boolean) => {
  const { data, error, isLoading } = useQuery<Icons[], Error>({
    queryKey: ['icons'],
    queryFn: fetchIcons,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
