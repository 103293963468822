import React from 'react';

const TechnicalEducationIcon = () => {
  return (
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 1.5H0.5V21.5H6.5V1.5Z" stroke="#8094AA" strokeLinecap="square" />
      <path d="M6.5 5H12.5V21.5H6.5V5Z" stroke="#8094AA" strokeLinecap="square" />
      <path d="M0.5 17H6.5" stroke="#8094AA" strokeLinecap="square" />
      <path d="M6.5 19H12.5" stroke="#8094AA" strokeLinecap="square" />
      <path d="M3.5 4.5V9.75" stroke="#8094AA" strokeLinecap="square" />
      <path d="M9.5 8V13.25" stroke="#8094AA" strokeLinecap="square" />
      <path
        d="M17.676 1.26143L12.7188 1.91406L15.3293 21.743L20.2865 21.0903L17.676 1.26143Z"
        stroke="#8094AA"
        strokeLinecap="square"
      />
      <path d="M15.5859 4.5625L16.2385 9.51975" stroke="#8094AA" strokeLinecap="square" />
    </svg>
  );
};

export default TechnicalEducationIcon;
