import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import CountryDropdown from './CountryDropdown';
import FilterUserType from './UserTypes';
import { GridActionEnum } from '../../../../../common/enums/GridActionEnum';
import {
  useGridDispatch,
  useGridState,
} from '../../../../../common/GridContext/GridContextProvider';
import { TngApplyFilterBtn, TngDivider, TngGrid } from '../../../../../components/common';
import { RootState } from '../../../../../store';
import { ManageUserFilter } from '../../modals/ManageUserFilter';
import { ManageUserFilterContext } from '../../modals/ManageUserFilterContext';
import {
  checkIfManageUserFiltersChanged,
  initializeFilterState,
  isAnyFilterSelected,
} from '../../utils/filterHelper';

export const Filter = () => {
  // filters from context
  const { filters }: { filters: ManageUserFilterContext } = useGridState();
  const dispatch = useGridDispatch();
  const userTypes = useSelector((state: RootState) => state.master.userTypes);

  const masterState = useSelector((state: RootState) => state.master);
  const countries = masterState.countries;
  // states for filter
  const [filterObj, setFiltersObj] = useState<ManageUserFilter>(initializeFilterState(filters));

  const defaultFilterState: ManageUserFilter = initializeFilterState(filters);

  // Check if there are any active filters
  const hasFilters = checkIfManageUserFiltersChanged(filterObj, defaultFilterState);

  // click handler of apply filter button which updates the context and calls onApply callback
  const applyFilter = () => {
    dispatch({ type: GridActionEnum.SET_FILTERS, payload: { ...filterObj } });
    dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: false });
    if (isAnyFilterSelected(filterObj)) {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: true });
    } else {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: false });
    }
  };

  return (
    <TngGrid container className="order_history_filter filter_container">
      <FilterUserType userTypes={userTypes} filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <CountryDropdown country={countries} filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <TngApplyFilterBtn hasFilters={hasFilters} applyFilter={applyFilter} />
    </TngGrid>
  );
};
