import { Customer } from '../../../common/interfaces/customers/customers';
import { FetchCustomersResponse } from '../../../common/interfaces/customers/fetchCustomersResponse';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Fetches end user customer data from the given URL.
@returns {Promise<Customer[]>} - A promise that resolves to the end users customers data.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchEndUserCustomers = async (
  customerName: string,
  accountId: string,
): Promise<Customer[]> => {
  try {
    let url = getUrl(URLS.CUSTOMERS, { accountId: accountId });
    url = `${url}?customerName=${customerName}`;

    const { data } = await axiosInstance.get<FetchCustomersResponse>(url);
    return data.data;
  } catch (err) {
    // Rethrow or handle the error as needed
    throw new Error('Failed to fetch customers');
  }
};

/**
Fetches sold through customer data from the given URL.
@returns {Promise<Customer[]>} - A promise that resolves to the sold through customers data.
@throws {Error} - Throws an error if the request fails.
*/
export const fetchSoldThroughCustomers = async (
  customerName: string,
  accountId: string,
): Promise<Customer[]> => {
  try {
    let url = getUrl(URLS.CUSTOMERS, { accountId: accountId });
    url = `${url}?isSoldThroughAccount=true&customerName=${customerName}`;

    const { data } = await axiosInstance.get<FetchCustomersResponse>(url);
    return data.data;
  } catch (err) {
    // Rethrow or handle the error as needed
    throw new Error('Failed to fetch sold through customers');
  }
};
