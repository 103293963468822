import { jwtDecode } from 'jwt-decode';

import { UserTypesEmuns } from '../common/enums/UserTypesEmuns';

interface TokenPayload {
  userType: UserTypesEmuns;
}

export const getUserTypeFromToken = (token: string): UserTypesEmuns | null => {
  try {
    const decodedToken = jwtDecode<TokenPayload>(token);
    return decodedToken.userType || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};
