import { AccountDetailsResponse } from '../../../../../common/interfaces/accountsettings/accountdetails/accountDetailsResponse';
import { AccountInfoDetails } from '../modals/AccountInfoDetails';
import { AddressInfoDetails } from '../modals/AddressInfoDetails';
import { PartnerLevelDetails } from '../modals/PartnerLevelDetails';

export const dataMapper = (accountDetail: AccountDetailsResponse) => {
  const accountInfo: AccountInfoDetails = {
    name: accountDetail.name,
    parentName: accountDetail.parentName,
    website: accountDetail.website,
    phoneNumber: accountDetail.phoneNumber,
    partnerNumber: accountDetail.partnerNumber,
    supportId: accountDetail.supportId,
  };
  const partnerInfo: PartnerLevelDetails = {
    workflowAutomationPartnerLevel: accountDetail.workflowAutomationPartnerLevel,
    invoiceAutomationPartnerLevel: accountDetail.invoiceAutomationPartnerLevel,
    intelligentCapturePartnerLevel: accountDetail.intelligentCapturePartnerLevel,
    powerPdfPartnerLevel: accountDetail.powerPdfPartnerLevel,
    controlSuitePartnerLevel: accountDetail.controlSuitePartnerLevel,
    printixPartnerLevel: accountDetail.printixPartnerLevel,
    totalAgilityCloudPartnerLevel: accountDetail.totalAgilityCloudPartnerLevel,
    invoiceAgilityPartnerLevel: accountDetail.invoiceAgilityPartnerLevel,
  };
  const addressInfo: AddressInfoDetails = {
    billToAddress: accountDetail.billToAddress,
    soldToAddress: accountDetail.soldToAddress,
  };
  return { accountInfo, partnerInfo, addressInfo };
};
