import Translations from '../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../common/interfaces/ColumnDef';

// this function is used to create column definations for order history grid

export const getColumnHeaders = (isContactVisible: boolean): ColumnDef[] => {
  return [
    {
      name: 'certificationName',
      displayName: Translations.technicalCertifications.identifier,
      colWidth: 200,
    },
    {
      name: 'certificationDescription',
      displayName: Translations.technicalCertifications.certificationName,
      showTooltip: true,
      colWidth: 350,
    },
    ...(isContactVisible
      ? [
          {
            name: 'contactName',
            displayName: Translations.technicalCertifications.contact,
            colWidth: 200,
          },
        ]
      : []),
    {
      name: 'certificationDate',
      displayName: Translations.technicalCertifications.certificationDate,
    },
    {
      name: 'grade',
      displayName: Translations.technicalCertifications.grade,
      colWidth: 90,
    },
  ];
};
