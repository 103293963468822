import { MenuItemEnum } from '../../../../../common/enums/MenuItemEnum';
import { Menu } from '../../../../../common/interfaces/userAdmin/Menu';
import { MenuItemModal } from '../modal/MenuItemModal';

const getActiveItems = (itemName: string) => {
  switch (itemName.toLowerCase()) {
    case MenuItemEnum.DASHBOARD:
      return ['/dashboard'];
    case MenuItemEnum.MARKETING:
      return ['/partner-program-guide'];
    case MenuItemEnum.SALES:
      return ['/pricelists'];
    case MenuItemEnum.MANAGE_CUSTOMERS:
      return ['/order-history', '/order-summary'];
    case MenuItemEnum.COMMUNITY:
      return [''];
    case MenuItemEnum.KNOWLEDGE_CENTER:
      return ['/technical-certifications'];
    case MenuItemEnum.SUPPORT:
      return [''];
    case MenuItemEnum.USER_MANAGEMENT:
      return ['/active-users', '/user-details'];
    default:
      return [];
  }
};

export const mapMenuData = (menuData: Menu[], isFirstLevel = true): MenuItemModal[] => {
  return menuData.map((item) => ({
    itemName: item.itemName,
    routeRelativePath: item.routeRelativePath,
    iconId: item.iconId,
    navigationUrl: item.navigationUrl,
    items: item.items ? mapMenuData(item.items, false) : [],
    activeItems: getActiveItems(item.itemName),
    isParent: isFirstLevel || (!!item.items && item.items.length > 0), // Always true for first level
  }));
};
